$('span.ajax-loader').hide();

document.addEventListener('wpcf7submit', function (event) {
	switch (event.detail.status) {
		case 'wpcf7c_confirmed':
			$('body').addClass('wp-contactform7-confirm-page');
			// $("input[type=radio]:not(:checked)").closest('.wpcf7-list-item').hide();
			// $("input[type=checkbox]:not(:checked)").closest('.wpcf7-list-item').hide();
			// // $(".txt_comment").hide();
			// $("input[name=inquiry_mail_confirm]").closest('.sw-form__emailItem').hide();
			// $('.step1').removeClass('-on');
			// $('.step2').addClass('-on');
			// $("div.c-form").addClass("is-confirm");
			// $('li.c-form__stepItem').eq(0).removeClass('is-current');
			// $('li.c-form__stepItem').eq(1).addClass('is-current');
			// $('li.c-form__stepItem').eq(0).addClass('is-complete');
			break;
	}
}, false);

document.addEventListener('wpcf7mailsent', function (event) {
  location = '/family/gas/credit/thanks/';
	// if (document.querySelector('.is-opencampus_form')) {
	// 	location = '/opencampus_form/thanks/';
	// }
}, false);
$('.wpcf7c-btn-back').on('click', function () {
	$('body').removeClass('wp-contactform7-confirm-page');
	// $("input[type=radio]:not(:checked)").closest('.wpcf7-list-item').show();
	// $("input[type=checkbox]:not(:checked)").closest('.wpcf7-list-item').show();
	// $("input[name=inquiry_mail_confirm]").closest('.sw-form__emailItem').show();
});


// $('.your-speciality-medical label').on('click', function () {
// 	$(".your-training-welfare input").prop("checked", false);
// });
// $('.your-speciality-child label').on('click', function () {
// 	$(".your-training-welfare input").prop("checked", false);
// });
// $('.your-training-welfare label').on('click', function () {
// 	$(".your-speciality-medical input").prop("checked", false);
// 	$(".your-speciality-child input").prop("checked", false);
// });