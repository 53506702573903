import PerfectScrollbar from "perfect-scrollbar";

// テーブルラッパーのPerfectScrollbar初期化
if ($(".p-detail-table__wrapper").length) {
  const tableWrappers = $(".p-detail-table__wrapper");
  const tableCount = tableWrappers.length;
  
  // 既存のPerfectScrollbarインスタンスをクリア
  tableWrappers.each(function() {
    if ($(this).hasClass('ps')) {
      // すでにPerfectScrollbarが適用されている場合、それを破棄
      const psInstance = $(this)[0].perfectScrollbar;
      if (psInstance) {
        psInstance.destroy();
      }
    }
    
    // 既存のps関連クラスを削除
    $(this).removeClass(function(index, className) {
      return (className.match(/(^|\s)table-ps-item-\S+/g) || []).join(' ');
    });
  });
  
  // 各テーブル用のPerfectScrollbarインスタンスを配列で保持
  const tableScrollbars = [];
  
  for (let i = 0; i < tableCount; i++) {
    // 一意のクラス名を付与（indexを使用）
    const uniqueClass = `table-ps-item-${i + 1}`;
    tableWrappers.eq(i).addClass(uniqueClass);
    
    // 個別のインスタンスを作成して配列に保存
    tableScrollbars.push(new PerfectScrollbar(`.${uniqueClass}`, {
      suppressScrollY: false,
      wheelPropagation: true
    }));
  }
}

// 検査ラッパーのPerfectScrollbar
if ($(".p-detail-inspection__wrapper").length) {
  // 既存のインスタンスをクリア
  $(".p-detail-inspection__wrapper").each(function() {
    if ($(this).hasClass('ps')) {
      const psInstance = $(this)[0].perfectScrollbar;
      if (psInstance) {
        psInstance.destroy();
      }
    }
  });
  
  const inspectionScrollbar = new PerfectScrollbar(".p-detail-inspection__wrapper");
}

// 領域のPerfectScrollbar
if ($(".p-detail-area").length) {
  // 既存のインスタンスをクリア
  $(".p-detail-area").each(function() {
    if ($(this).hasClass('ps')) {
      const psInstance = $(this)[0].perfectScrollbar;
      if (psInstance) {
        psInstance.destroy();
      }
    }
  });
  
  const areaScrollbar = new PerfectScrollbar(".p-detail-area");
}

// サムネイルのPerfectScrollbar
if ($(".p-detail-thumbnail").length && $(".is-ps").length) {
  const thumbnails = $(".is-ps");
  const thumbCount = thumbnails.length;
  
  // 既存のインスタンスをクリア
  thumbnails.each(function() {
    if ($(this).hasClass('ps')) {
      const psInstance = $(this)[0].perfectScrollbar;
      if (psInstance) {
        psInstance.destroy();
      }
    }
    
    // 既存のps関連クラスを削除
    $(this).removeClass(function(index, className) {
      return (className.match(/(^|\s)thumbnail-ps-item-\S+/g) || []).join(' ');
    });
  });
  
  const thumbnailScrollbars = [];
  
  for (let i = 0; i < thumbCount; i++) {
    const uniqueClass = `thumbnail-ps-item-${i + 1}`;
    thumbnails.eq(i).addClass(uniqueClass);
    thumbnailScrollbars.push(new PerfectScrollbar(`.${uniqueClass}`));
  }
}

// チャートのPerfectScrollbar
if ($(".p-detail-chart").length) {
  const charts = $(".p-detail-chart");
  const chartCount = charts.length;
  
  // 既存のインスタンスをクリア
  charts.each(function() {
    if ($(this).hasClass('ps')) {
      const psInstance = $(this)[0].perfectScrollbar;
      if (psInstance) {
        psInstance.destroy();
      }
    }
    
    // 既存のps関連クラスを削除
    $(this).removeClass(function(index, className) {
      return (className.match(/(^|\s)chart-ps-item-\S+/g) || []).join(' ');
    });
  });
  
  const chartScrollbars = [];
  
  for (let i = 0; i < chartCount; i++) {
    const uniqueClass = `chart-ps-item-${i + 1}`;
    charts.eq(i).addClass(uniqueClass);
    chartScrollbars.push(new PerfectScrollbar(`.${uniqueClass}`));
  }
}

// 故障のPerfectScrollbar
if ($(".failure-perfectScrollbar").length) {
  // 既存のインスタンスをクリア
  $(".failure-perfectScrollbar").each(function() {
    if ($(this).hasClass('ps')) {
      const psInstance = $(this)[0].perfectScrollbar;
      if (psInstance) {
        psInstance.destroy();
      }
    }
  });
  
  const failureScrollbar = new PerfectScrollbar(".failure-perfectScrollbar");
}

// ウィンドウのリサイズ時にPerfectScrollbarを更新
$(window).resize(function() {
  // すべてのPerfectScrollbarインスタンスを更新する
  $('.ps').each(function() {
    const psInstance = this.perfectScrollbar;
    if (psInstance) {
      psInstance.update();
    }
  });
});

// DOMの変更（表示/非表示の切り替えなど）があった場合にPerfectScrollbarを更新する
// 必要に応じてこの関数を適切なイベントハンドラに結びつける
function updateAllScrollbars() {
  $('.ps').each(function() {
    const psInstance = this.perfectScrollbar;
    if (psInstance) {
      psInstance.update();
    }
  });
}

// 例: タブの切り替えなどで使用する場合
// $('.tab-button').on('click', function() {
//   // タブの表示処理
//   // ...
//   // スクロールバーの更新
//   updateAllScrollbars();
// });

