import Swiper from "swiper";

if ($(".top-hero").length) {
  const heroSlides = document.querySelectorAll(
    ".top-hero-thumbnail .swiper-slide"
  );
  const heroPager = document.querySelector(".top-hero__pager");

  if (heroSlides.length > 1) {
    const hero = new Swiper(".top-hero-thumbnail", {
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      loop: true,
      speed: 1200,
      slideToClickedSlide: false,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".top-hero__pager",
        clickable: true,
      },
    });
  } else {
    heroPager.style.display = "none";
  }
}

if ($(".top-banner").length) {
  const banner = new Swiper(".top-banner-slider", {
    slidesPerView: 3.5,
    spaceBetween: 20,
    loop: true,
    speed: 1200,
    slideToClickedSlide: false,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      prevEl: ".top-banner-slider__prev",
      nextEl: ".top-banner-slider__next",
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      700: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      }
    },
  });
}

if ($(".top-hero").length) {
  const hero = new Swiper('.top-hero__contents', {
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    loop: true,
    effect: "fade",
    speed: 1200,
    slideToClickedSlide: false,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".top-hero__pager",
      clickable: true
    },
  });
}

if ($(".l-banner").length) {
  const banner = new Swiper('.l-banner-slider', {
    slidesPerView: 3,
    spaceBetween: 20,
    loop: true,
    speed: 1200,
    slideToClickedSlide: false,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      prevEl: '.l-banner-slider__prev',
      nextEl: '.l-banner-slider__next',
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
      },
      700: {
        slidesPerView: 1,
      }
    },
  });
}

if ($(".l-slideShow").length) {
  const slideShow = new Swiper(".l-slideShow", {
    allowTouchMove: false,
    freeMode: true,
    loop: true,
    loopedSlides: 4,
    slidesPerView: 3.5,
    spaceBetween: 0,
    speed: 20000,
    autoplay: {
      delay: 1,
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2.5,
      }
    },
  });
}

if ($(".-slider--01").length) {
  const contents01 = new Swiper('.-slider--01', {
    slidesPerView: 1.73,
    spaceBetween: 40,
    centeredSlides: true,
    loop: true,
    speed: 1200,
    slideToClickedSlide: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView: 1.4
      }
    },
  });
}

if ($(".-slider--02").length) {
  const contents02 = new Swiper('.-slider--02', {
    slidesPerView: 1.73,
    spaceBetween: 40,
    centeredSlides: true,
    loop: true,
    speed: 1200,
    slideToClickedSlide: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView: 1.4
      }
    },
  });
}

if ($(".-slider--03").length) {
  const contents03 = new Swiper('.-slider--03', {
    slidesPerView: 1.73,
    spaceBetween: 40,
    centeredSlides: true,
    loop: true,
    speed: 1200,
    slideToClickedSlide: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView: 1.4
      }
    },
  });
}

if ($(".-slider--04").length) {
  const contents04 = new Swiper('.-slider--04', {
    slidesPerView: 1.73,
    spaceBetween: 40,
    centeredSlides: true,
    loop: true,
    speed: 1200,
    slideToClickedSlide: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView: 1.4
      }
    },
  });
}