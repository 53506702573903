import swiper from "swiper";

if ($(".top-hero").length) {
  var hero = new swiper('.top-hero-thumbnail', {
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    loop: true,
    // effect: "fade",
    speed: 1200,
    slideToClickedSlide: false,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1200,
    pagination: {
      el: ".top-hero__pager",
      clickable: true
    },
  });
}

if ($(".top-banner").length) {
  var banner = new swiper('.top-banner-slider', {
    slidesPerView: 3,
    spaceBetween: 20,
    // centeredSlides: true,
    loop: true,
    // effect: "fade",
    speed: 1200,
    slideToClickedSlide: false,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 1200,
    navigation: {
      prevEl: '.top-banner-slider__prev',
      nextEl: '.top-banner-slider__next',
    },
    breakpoints: {
      1024: {
        slidesPerView: 2
      },
      700: {
        slidesPerView: 1
      },
    },
  });
}

