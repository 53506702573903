import jqueryImagesCompare from "jquery-images-compare";

// $("#container1").twentytwenty();

if ($(".p-detail-preview-thumbnail").length) {

  if ($("#num01").length) {
    var num01 = $('#num01').imagesCompare();
  }
  if ($("#num02").length) {
    var num02 = $('#num02').imagesCompare();
  }
  if ($("#num03").length) {
    var num03 = $('#num03').imagesCompare();
  }
  if ($("#num04").length) {
    var num04 = $('#num04').imagesCompare();
  }
  if ($("#num05").length) {
    var num05 = $('#num05').imagesCompare();
  }
  if ($("#num06").length) {
    var num06 = $('#num06').imagesCompare();
  }
  if ($("#num07").length) {
    var num07 = $('#num07').imagesCompare();
  }
  if ($("#num08").length) {
    var num08 = $('#num08').imagesCompare();
  }
}

