import isMobile from '../../plugins/is-mobile.js';

if (isMobile(768) && $(".pop-mune").length) {
  $(function () {
    setTimeout(function () {
      $('body').addClass('page-transition');
    }, 100);
  });
}

$('.top-contents-pop-item').click(function () {
  if (isMobile(768)) {
    var id = $(this).attr('id');
    // $('body').attr('id', 'pop-' + id);
    $('body').addClass('pop-' + id);
    $('body').addClass('is-pop-open');
    $('body').removeClass('is-pop-close');
  }
});

$('.pop-mune__cancel').click(function () {
  if (isMobile(768)) {
    // $('body').removeAttr('id', 'city');
    // $('body').removeAttr('id', 'propane');
    $('body').removeClass('pop-city');
    $('body').removeClass('pop-propane');
    $('body').removeClass('is-pop-open');
  } $('body').addClass('is-pop-close');
});