import PerfectScrollbar from "perfect-scrollbar";

// if (document.getElementById('p-detail-table__wrapper')) {
if ($(".p-detail-table__wrapper").length) {
  var cnt = $(".p-detail-table__wrapper").length;
  for (let step = 1; step < cnt + 1; step++) {
    $(".p-detail-table__wrapper").eq(step - 1).addClass('table-ps-item-' + step);
    var ps = new PerfectScrollbar('.table-ps-item-' + step);
  }
}

if ($(".p-detail-inspection__wrapper").length) {
  var ps = new PerfectScrollbar(".p-detail-inspection__wrapper");
}

if ($(".p-detail-area").length) {
  var ps = new PerfectScrollbar(".p-detail-area");
}

if ($(".p-detail-thumbnail").length && $(".is-ps").length) {
  var cnt = $(".is-ps").length;
  for (let step = 1; step < cnt + 1; step++) {
    $(".is-ps").eq(step - 1).addClass('thumbnail-ps-item-' + step);
    var ps = new PerfectScrollbar(".thumbnail-ps-item-" + step);
  }
}

if ($(".p-detail-chart").length) {
  var cnt = $(".p-detail-chart").length;
  for (let step = 1; step < cnt + 1; step++) {
    $(".p-detail-chart").eq(step - 1).addClass('chart-ps-item-' + step);
    var ps = new PerfectScrollbar('.chart-ps-item-' + step);
  }
}

if ($(".failure-perfectScrollbar").length) {
  var ps = new PerfectScrollbar(".failure-perfectScrollbar");
}
